import { RouteMeta, RouteRecordRaw } from 'vue-router';
import {
  GoodsRouteExports,
  MultipleGoods,
  ProductRouteType,
  routeHandler
} from '~/utils/route-generate';

// 产品路由
const GoodsRoutes: RouteRecordRaw[] = [
  {
    path: '/goods',
    name: 'goods_detail',
    component: () => import('~/views/goods/goods-info/GoodsInfo.vue'),
    meta: {
      headerOpacity: false,
      requireAuth: false,
      notSticky: true,
    }
  }
];

// 订单路由
const OrderRoutes: RouteRecordRaw[] = [];

// 续费路由
const RenewalRoutes: RouteRecordRaw[] = [];

// 控制台路由
const ConsoleRoutes: RouteRecordRaw[] = [];

// 产品分类
type ProductRouteMapValue = {
  path: string;
  meta: RouteMeta;
}
const GoodsFirmRouteMap = new Map<string, Record<ProductRouteType, ProductRouteMapValue[]>>();
// 多产品路由配置
const multipleRoutesGoods = new Map<string, MultipleGoods>([
  // todo 现在还没有接入生态云，后续接入后在进行菜单配置
  [
    'ecology-cloud',
    { menuProps: { name: '生态云', needExpend: true, show: false } }
  ],
]);

// 获取除goods-routes/index.ts以外的所有路由文件
const goodsRoutesFiles = import.meta.glob(
  '~/router/goods-routes/*/*.ts',
  { eager: true }
) as Record<string, GoodsRouteExports>;

// 插入类型
const routePushMap = new Map<ProductRouteType, (route: RouteRecordRaw[]) => void>([
  // 购买
  ['purchase', route => GoodsRoutes.push(...route)],
  // 订单
  ['order', route => OrderRoutes.push(...route)],
  // 续费
  ['renewal', route => RenewalRoutes.push(...route)],
  // 控制台
  ['consolePage', route => ConsoleRoutes.push(...route)]
]);
const getIntarctPath = (route: RouteRecordRaw, parent: string, pathArray: ProductRouteMapValue[]) => {
  const { path, children, meta } = route;
  const nowPath = parent + path;
  if (children) {
    for (let i = 0; i < children.length; i++) {
      const element = children[i];
      getIntarctPath(element, `${nowPath}/`, pathArray);
    }
    return;
  }
  if (meta?.requireAuth) {
    const paramsPathIndex = nowPath.indexOf('/:');
    const finalPath = nowPath.substring(0, paramsPathIndex > 0 ? paramsPathIndex : void 0);
    const routeItem = {
      path: finalPath[0] === '/' ? finalPath : `/${finalPath}`,
      meta
    };
    pathArray.push(routeItem);
  }
};
const hanlerGoodsFirmType = (key: string, productRoutes: [string, RouteRecordRaw | RouteRecordRaw[]][]) => {
  // 获取分类路由
  const filePathSplit = key.split('/');
  const goodsFirmType = filePathSplit[filePathSplit.length - 2];
  const GoodsRouteMapItem = GoodsFirmRouteMap.get(goodsFirmType) || {
    purchase: [],
    order: [],
    renewal: [],
    consolePage: []
  };
  for (let i = 0; i < productRoutes.length; i++) {
    const [routeType, route] = productRoutes[i];
    const routes = Array.isArray(route) ? route : [route];
    const pathArr: ProductRouteMapValue[] = [];
    for (let j = 0; j < routes.length; j++) {
      const parentPath = routeType === 'consolePage' ? '/Console/' : '';
      getIntarctPath(routes[j], parentPath, pathArr);
    }
    GoodsRouteMapItem[routeType as ProductRouteType].push(...pathArr);
  }
  GoodsFirmRouteMap.set(goodsFirmType, GoodsRouteMapItem);
};

// 将文件执行module导入获取module对象
for (const key in goodsRoutesFiles) {
  const fileModule = goodsRoutesFiles[key] as object;
  // 获取所有类型的产品路由
  const productRoutes = Object.entries(fileModule);
  // 处理分类路由统计
  hanlerGoodsFirmType(key, productRoutes);
  // 处理多产品多路由类型厂商
  const multipRoute = routeHandler({
    key,
    routeMap: multipleRoutesGoods,
    exportRoutes: goodsRoutesFiles
  });
  // 单个文件产品路由直接插入
  if (!multipRoute) {
    // 按类型进行填充
    for (let i = 0; i < productRoutes.length; i++) {
      // 扁平化
      const routes = productRoutes[i].flat(Infinity);
      // 获取类型
      const type = routes.shift() as ProductRouteType;
      // 插入到指定路由集合下
      routePushMap.get(type)?.call(null, routes);
    }
  }
}

// 遍历多产品路由配置
multipleRoutesGoods.forEach(({ route }) => {
  if (route) {
    const { order, renewal, consolePage, purchase } = route;
    if (purchase) {
      GoodsRoutes.push(...purchase);
    }
    if (order) {
      OrderRoutes.push(order);
    }
    if (renewal) {
      RenewalRoutes.push(renewal);
    }
    if (consolePage) {
      ConsoleRoutes.push(...consolePage);
    }
  }
});

export {
  GoodsRoutes,
  OrderRoutes,
  RenewalRoutes,
  ConsoleRoutes,
  GoodsFirmRouteMap,
  multipleRoutesGoods
};