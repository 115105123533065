import { defineStore } from 'pinia';
import { VCertIdentityList } from '~/models/product/v-cert-cloud/sign-user';
import { API_V_CERT_SIGN } from '~/api/product/encology-colud/v-cert-cloud/v-cert-sign';
import { customStorage } from '~/utils/storage';
import { StorageKeys } from '~/constant/storage-keys-enum';

type StateType = {
  // 成员和企业列表
  identityList: VCertIdentityList[],
  identityLoading: boolean
  // 用户数据
  identityInfo: VCertIdentityList
};

export const vCertSignStore = defineStore('vCertSignStore', {
  state: () => {
    const data: StateType = {
      identityList: [],
      identityLoading: false,
      identityInfo: {} as VCertIdentityList
    };
    return data;
  },
  actions: {
    async getVCertSignIdentity (id?: string) {
      this.identityLoading = true;
      const res = await API_V_CERT_SIGN.getIdentity();
      this.identityList = res;
      const isSelect = customStorage.getItem<VCertIdentityList>(StorageKeys.LOCAL_IDENTITY);
      if (this.identityList.length) {
        if (id) {
          this.identityInfo = this.identityList.find(item => item.id === id) || this.identityList[0];
        } else {
          if (isSelect) {
            this.identityInfo = isSelect.value;
          } else {
            this.identityInfo = this.identityList[0];
          }
        }
      }
      this.changeIdentity(this.identityInfo);
      this.identityLoading = false;
    },
    changeIdentity (row: VCertIdentityList) {
      this.identityInfo = row;
      customStorage.setItem<VCertIdentityList>({
        key: StorageKeys.LOCAL_IDENTITY,
        value: row,
        timer: 1000 * 60 * 60 * 24 - 10000
      });
    }
  }
});