// 不需要footer、token、header不移动
const userEnterMeta = {
  requireAuth: false,
  footerHidden: false,
  notSticky: true
};
// 未登录产品购买页面meta
const notLoggedPurchaseMeta = {
  requireAuth: false,
  footerHidden: true,
  headerOpacity: false,
};


// 产品购买页面meta
const purchaseMeta = {
  headerOpacity: false,
  requireAuth: true,
  footerHidden: true,
  authentication: true
};

// 个人中心基础通用meta
const individualBaseMeta = {
  requireAuth: true,
  authentication: true,
};

// 控制台通用meta
const consoleBaseMeta = {
  requireAuth: true,
  authentication: true,
  footerHidden: true,
  consoleButton: true,
};

export {
  userEnterMeta,
  purchaseMeta,
  consoleBaseMeta,
  individualBaseMeta,
  notLoggedPurchaseMeta
};