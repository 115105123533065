import { APIRequest } from '~/api/config';
import { CurrentPageParams } from '~/models/response-type';
import {
  VCertSignProductRecord,
  VCertSignOrderForm,
} from '~/models/product/v-cert-cloud/sign-order';
import {
  VCertFileRecords,
  VCertContractById,
  VCertContractSearch,
  VCertContractRecords,
  VCertContractForm,
  VCertContractSignerFrom,
  VCertContractFileList,
  ContractDraftDetail,
  UpdateContractDraft,
  DownloadContractFileType,
  VCertgetContractByIdType,
  ContracttDetail,
  SignerDetail
} from '~/models/product/v-cert-cloud/sign-contract';
import { VCertEnterpriseAddForm } from '~/models/product/v-cert-cloud/sign-enterprise';
import {
  VCertPersonalAuthenticationForm,
  VCertAddEnterpriseForm,
  VCertIdentityList
} from '~/models/product/v-cert-cloud/sign-user';
import {
  CreatePersonalSealForm,
  CreateEnterpriseSealForm,
  delSeal,
  VCertEnterpriseSealRecords,
  VCertPersonalSealRecords,
  VCertUserSealSearch,
  VCertEnterpriceSealSearch
} from '~/models/product/v-cert-cloud/sign-seal';
// 合同管理
const contractAPI = {
  // 分页查询合同列表
  contractPage: (params: VCertContractSearch) => {
    return APIRequest.get<VCertContractRecords>({ url: 'itrus/itrustcontract/getContractPage', params });
  },
  // 创建草稿
  createDraftContract: (data: VCertContractForm) => APIRequest.post<boolean>({ url: 'itrus/itrustcontract/addDraft', data }),
  // 直接创建合同
  createContract: (data: VCertContractForm) => {
    return APIRequest.post<string>({ url: 'itrus/itrustcontract/addContractInfo', data,
      retry: 0,
      retryDelay: 100000,
    });
  },
  // 草稿状态创建合同
  draftOfInitiateContract: (id: string) => {
    return APIRequest.post<boolean>({ url: 'itrus/itrustcontract/draftOfInitiateContract', data: { id },
      retry: 0,
      retryDelay: 100000,
    });
  },
  // 保存编辑合同
  updateDraft: (data: UpdateContractDraft) => {
    return APIRequest.post<boolean>({ url: 'itrus/itrustcontract/updateDraft', data });
  },
  // 发起合同
  sendContract: (contractId: string, initiatorName: string) => {
    return APIRequest.post<boolean>({ url: 'itrus/itrustcontract/send', data: { contractId, initiatorName } });
  },
  // 查看当前合同的文件及签署人
  getContractFile: (params: VCertContractById) => {
    return APIRequest.get<VCertContractFileList>({
      url: 'itrus/itrustcontract/getContractFile',
      params
    });
  },
  // 删除预制合同 状态必须为草稿
  delContract: (contractId: string) => APIRequest.delete<boolean>({ url: `itrus/itrustcontract/${contractId}` }),
  // 下载已签署合同文件
  downloadContractFile: (contractId: string) => {
    return APIRequest.post<DownloadContractFileType>({
      url: 'itrus/itrustcontract/downloadContractFile',
      data: {
        contractId
      }
    });
  },
  // 校验合同是否过期
  getContractExpire: (contractId: string, signId: string) => {
    return APIRequest.get<boolean | null>({
      url: 'itrus/itrustcontract/getContractExpire',
      params: {
        contractId,
        signId
      }
    });
  },
  
  // 编辑回显
  getContractDraft: (id: string) => {
    return APIRequest.get<ContractDraftDetail>({
      url: 'itrus/itrustcontract/getDraft',
      params: {
        id
      }
    });
  },
  // 发起签署
  addSigner: (data: VCertContractSignerFrom) => {
    return APIRequest.post<boolean>({
      url: 'itrus/itrustcontract/startSignByFile',
      data,
    });
  },
  getContractById: (params: VCertgetContractByIdType) => {
    return APIRequest.get<string>({
      url: 'itrus/itrustcontract/getContractById',
      params,
    });
  },
  // 获取合同签署详情
  getContractDetails: (params: VCertgetContractByIdType) => {
    return APIRequest.get<ContracttDetail>({
      url: 'itrus/itrustcontract/getContractDetails',
      params,
    });
  },
  // 根据key值获取信息
  getSignerByRedis: (sign: string) => {
    return APIRequest.get<SignerDetail>({
      url: 'itrus/itrustcontract/getSignerByRedis',
      params: {
        sign
      },
    });
  },
};

// 成员管理
const userAPI = {
  // 个人认证
  personalAuthentication: (data: VCertPersonalAuthenticationForm) => {
    return APIRequest.post<boolean>({ url: 'itrus/itrustuser/addItrustUser', data });
  },
  // 新增企业
  addItrustEnterprise: (data: VCertAddEnterpriseForm) => {
    return APIRequest.post<boolean>({ url: 'itrus/itrustenterprise/addItrustEnterprise', data });
  },
  
  // 查询所有身份列表
  getIdentity: () => {
    return APIRequest.get<VCertIdentityList[]>({ url: '/itrus/itrustuser/getIdentity' });
  },
};

// 企业管理
const enterpriseAPI = {
  // 添加企业
  addEnterprise: (data: VCertEnterpriseAddForm) => {
    return APIRequest.post<boolean>({
      url: 'itrus/itrustenterprise/addItrustEnterprise',
      data
    });
  }
};

// 上传
const uploadAPI = {
  // 上传文件
  uploadFile: (data: FormData) => {
    return APIRequest.post<boolean>({
      url: 'itrus/itrustuploadfile/uploadFile',
      data
    });
  },
  // 删除文件
  delFile: (fileId: string) => APIRequest.delete<boolean>({ url: `itrus/itrustuploadfile/${fileId}` }),
  // 分页查看上传文件
  filePage: (params: CurrentPageParams) => {
    return APIRequest.get<VCertFileRecords>({
      url: 'itrus/itrustuploadfile/page',
      params
    });
  },
  // 根据链接获取合同文件
  getBlobFile: (filePath: string) => {
    return APIRequest.get<Blob>({
      url: 'itrus/itrustuploadfile/castWordToPdf',
      params: { filePath }
    });
  },
};
// 印章
const sealAPI = {
  
  // 查询个人印章分页
  personSealPage: (params: VCertUserSealSearch) => {
    return APIRequest.get<VCertPersonalSealRecords>({
      url: 'itrus/itrustuser/personSealList',
      params
    });
  },

  // 查询企业印章分页
  enterpriceSealPage: (params: VCertEnterpriceSealSearch) => {
    return APIRequest.get<VCertEnterpriseSealRecords>({
      url: 'itrus/itrustenterprise/sealPage',
      params
    });
  },
  
  // 创建个人印章
  CreatePersonalSeal: (data: CreatePersonalSealForm) => {
    return APIRequest.post<boolean>({
      url: 'itrus/itrustuser/createUserSeal',
      data
    });
  },

  // 创建企业印章
  addEnterpriseSeal: (data: CreateEnterpriseSealForm) => {
    return APIRequest.post<boolean>({
      url: 'itrus/itrustenterprise/createSeal',
      data
    });
  },

  // 删除印章
  delSeal: (data: delSeal) => {
    return APIRequest.post<boolean>({ url: 'itrus/itrustenterprise/delSeal', data });
  }
};

// 购买
const purchaseAPI = {
  // 获取产品
  getVCertProducts: () => {
    return APIRequest.get<VCertSignProductRecord[]>({
      url: 'itrus/itrusproduct/getItrusProduct'
    });
  },
  // 创建订单
  createOrder: (data: VCertSignOrderForm) => {
    return APIRequest.post<{ orderNum: string }>({
      url: 'itrus/itrusproduct/createOrder',
      data
    });
  }
};

export const API_V_CERT_SIGN = {
  ...purchaseAPI,
  ...uploadAPI,
  ...enterpriseAPI,
  ...userAPI,
  ...contractAPI,
  ...sealAPI
};
